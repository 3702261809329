// App.js
import React, { useState, useEffect } from "react";
import Modal from "./model";

const App = () => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsOpen(true);
    }, 1000); // Open popup after 3 seconds

    return () => clearTimeout(timer); // Cleanup the timer on unmount
  }, []);

  const closeModal = () => setIsOpen(false);

  return (
    <div>
      <Modal isOpen={isOpen} onClose={closeModal}>
        <div className="popupcontainer">
          <div className="popupimg">
            <div className="h-full"></div>
          </div>
          <div className="popiptext">
            <h2>Coming Soon!</h2>
            <p>
              Pav Dental is undergoing a makeover to bring you a brand-new
              clinic and an even brighter smile experience. We'll be ready to
              welcome you in just a few weeks!
            </p>
            <p>Stay tuned for our grand opening!</p>
         
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default App;
